import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot
} from '@angular/router';
import { of } from 'rxjs';
import { ExternalSignin } from '../../infrastructure/models';
import { ToasterService } from '../../infrastructure/services';
import { ApplicationLanguageService } from '../../infrastructure/services/application-language.service';
import { IdentityStore } from '../../infrastructure/store';
import { decodeJWTToken } from '../../shared/utils/jwt-tokens';

@Injectable()
export class SamlProcessorGuard implements CanActivate {
    constructor(
        private router: Router,
        private toasterService: ToasterService,
        private applicationLanguageService: ApplicationLanguageService,
        private identityStore: IdentityStore
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const { saml_token } = route.queryParams;
        if (!saml_token) {
            return of(true);
        }

        const data = this.parseSamlParams(saml_token)
        if (data) {
            this.identityStore.doExternalSignIn(data);
            // TODO: Refactoring is needed. First load data should be extracted to Resolver or ReactiveStore
            return this.applicationLanguageService.initLanguageSettings();
        } else {
            this.toasterService.showError({ message: 'Invalid token' }, true);
            this.router.navigateByUrl('/login');
            return of(false);
        }
    }

    private parseSamlParams(token: string) : ExternalSignin {
        const tokenData = decodeJWTToken(token, ['contactId', 'account']);
        if (!tokenData) {
            return null;
        }

        return {
            account: tokenData.account,
            token: token,
            id: tokenData.contactId,
            provider_name: 'saml'
        };
    }
}
