import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { String } from 'typescript-string-operations-ng4';
import { ResourcesConsts } from '../consts/resources.const';
import { ProfileProperty } from '../models/profile-property.model';
import { RegistrationSettings } from '../../app-take-survey/models';
import { SkipInterceptor } from '../consts/interceptors.const';
import { TakeSurveyProvider } from './take-survey.provider';
import { map } from 'rxjs/operators';
import { RegistrationCaptchaSetting } from '../models/signin.model';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoginProvider {
    constructor(private $http: HttpClient, private takeSurveyProvider: TakeSurveyProvider) {}

    getUserProfileProperties(): Observable<ProfileProperty[]> {
        const url: string = String.Format(ResourcesConsts.PROFILE_SETTINGS);
        return this.$http
            .get<ProfileProperty[]>(url, { headers: new HttpHeaders({ [SkipInterceptor.JWT]: 'true' }) });
    }

    getRegistrationSettings(): Observable<RegistrationSettings> {
        const url: string = String.Format(
            ResourcesConsts.REGISTRATION_SETTINGS
        );
        return this.$http
            .get<RegistrationSettings>(url, { headers: new HttpHeaders({ [SkipInterceptor.JWT]: 'true' }) });
    }

    resolveAccountName(): Observable<any> {
        const url: string = ResourcesConsts.ACCOUNT;
        return this.$http
            .get(url, { headers: new HttpHeaders({ [SkipInterceptor.JWT]: 'true' }) })
    }

    resetPassword(model): Observable<any> {
        const url: string = ResourcesConsts.RESET_PASSWORD;
        return this.$http
            .post(url, model);
    }

    getCaptchaKeysFromServer(settings: RegistrationSettings): Observable<RegistrationCaptchaSetting> {
        if( this.allowCaptcha(settings)) {
            return this.takeSurveyProvider.getCaptchaSiteKeys()
            .pipe(map(keys =>{return { settings: settings, captchaKeys: keys}})) 
        }
        return of({ settings: settings, captchaKeys: null});
    }
    private allowCaptcha(settings: RegistrationSettings){
        return (settings.allow_admin_login_captcha && environment.isAdminSite)
        ||
        (settings.allow_take_survey_login_captcha && !environment.isAdminSite)
    }
}
