import { TakeSurveyLoginComponent } from './take-survey-login/take-survey-login.component';
import { RegistrationNewAccountComponent } from './registration-new-account/registration-new-account.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/modules/shared.module';
import { LoginPageComponent } from './login/login-page.component';
import { CustomMaterialModule } from '../../shared/modules/material-components.module';
import { LazyTranslateLoaderModule } from '../../app-admin/entry-point/lazy-translate-loader.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import { ResetPasswordNewPasswordComponent } from './reset-password-new-password/reset-password-new-password.component';
import { ResetPasswordNotificationComponent } from './reset-password-notification/reset-password-notification.component';
import { RecaptchaModule } from 'ng-recaptcha';
const declarations = [
    // components
    TakeSurveyLoginComponent,
    RegistrationNewAccountComponent,
    ResetPasswordPageComponent,
    ResetPasswordComponent,
    ResetPasswordNewPasswordComponent,
    ResetPasswordNotificationComponent,
    LoginPageComponent,
];

const entryComponents = [
    // components
    /* put here app specific services */
];

@NgModule({
    imports: [
        // system
        CommonModule,
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        // custom
        CustomMaterialModule,
        SharedModule,
        // translation
        LazyTranslateLoaderModule,
        RecaptchaModule
    ],
    declarations: declarations,
    exports: [...declarations, ...entryComponents],
    entryComponents: entryComponents
})
export class TakeSurveyUserIdentityModule {}
