<div *ngIf="settings" class="cb-survey-login">
    <div class="take-survey-content">
        <div class="login-container">
            <h2
                *ngIf="surveyText.loginText === SurveyDefaultText.loginText"
                class="login-title"
                [innerHTML]="surveyText.loginText | keepHtml"
            ></h2>
            <p
                *ngIf="surveyText.loginText !== SurveyDefaultText.loginText"
                class="login-title"
                [innerHTML]="surveyText.loginText | keepHtml"
            ></p>
            <form [formGroup]="form" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <mat-form-field class="cb-full-width-input">
                        <input
                            matInput
                            placeholder="{{ surveyText.enterUserName }}"
                            formControlName="id"
                        />
                        <mat-error>
                            {{ 'FORMS.USERNAME-ERROR' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group">
                    <mat-form-field class="cb-full-width-input">
                        <input
                            matInput
                            type="password"
                            placeholder="{{ surveyText.enterUserPassword }}"
                            formControlName="password"
                        />
                        <mat-error [innerHTML]="surveyText.enterSurveyPassword | keepHtml">
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="cb-full-width-input" *ngIf="captchaKeys">
                    <re-captcha  class="center"
                        (resolved)="resolveCaptcha($event)"
                        #captchaRef="reCaptcha"
                        theme="ligth"
                        size="normal"
                        [siteKey]="captchaKeys.v2_recaptcha_site_key"
                    ></re-captcha>
                    <mat-error *ngIf="form.controls?.captcha?.errors">
                        {{ 'VALIDATION.CAPTCHA-REQUIRED' | translate }}
                    </mat-error>
                </div>
                <div class="links-group">
                    <div
                        class="btn-help-container"
                        *ngIf="settings?.allow_password_reset"
                    >
                        <button
                            type="button"
                            class="cb-button-link"
                            [routerLink]="['/reset-password']"
                            [innerHtml]="surveyText.resetPasswordLinkText | keepHtml"
                        >
                        </button>
                    </div>
                    <div
                        class="btn-help-container"
                        *ngIf="settings?.allow_public_registration"
                    >
                        <button
                            type="button"
                            class="cb-button-link"
                            [routerLink]="['/new-account']"
                            [innerHtml]="surveyText.selfRegistrationLinkText | keepHtml"
                        >
                        </button>
                    </div>
                </div>
                <div class="cb-take-survey-buttons-container">
                    <div>
                        <button
                        mat-flat-button
                        color="primary"
                        type="submit"
                        [disabled]="!form.valid"
                        [innerHtml]="surveyText.loginButton | keepHtml"
                    >
                    </button>
                    </div>
                    <div class="cb-take-survey-other-buttons-container">
                        <div class="strike">
                            <span>or</span>
                        </div>
                        <div class="cb-take-survey-other-buttons">
                            <button
                                mat-raised-button
                                class="saml-button"
                                type="button"
                                (click)="onSamlLogin()"
                            >
                                <span class="saml-text">SAML Login</span>
                            </button>
                        </div>
                    </div>
                </div>
                
            </form>
        </div>
    </div>
</div>
