import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginProvider } from '../../providers';
import { CaptchaKeys, RegistrationSettings } from '../../models';
import { SurveyDefaultText } from '../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../infrastructure/services/shared.service';
import { environment } from '../../../environments/environment';
import { switchMap } from 'rxjs/operators';

@Component({
    selector: 'cb-take-survey-login',
    templateUrl: './take-survey-login.component.html',
    styleUrls: ['./take-survey-login.component.scss']
})
export class TakeSurveyLoginComponent implements OnInit {
    public form: FormGroup;
    public account: string;
    public settings: RegistrationSettings;
    @Output() login: EventEmitter<any> = new EventEmitter();
    @Output() samlLogin: EventEmitter<void> = new EventEmitter<void>();
    public captchaKeys: CaptchaKeys;
    surveyText = SurveyDefaultText;
    SurveyDefaultText = SurveyDefaultText;
    isServerVersion = environment.name === 'server';

    constructor(
        private fb: FormBuilder,
        private loginProvider: LoginProvider,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            id: ['', Validators.required],
            password: ['', Validators.required]
        });
        this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
        });

        this.getSurveySettings();
    }

    getSurveySettings() {
        if(this.isServerVersion) {
            this.loginProvider.getRegistrationSettings().pipe(
                switchMap(settings=> this.loginProvider.getCaptchaKeysFromServer(settings))
            )
            .subscribe((settings) => {
                this.settings = settings.settings;
                this.captchaKeys = settings.captchaKeys;
                if(settings.captchaKeys){
                    this.form.addControl('captcha', this.fb.control('', Validators.required));
                }
            });
            return;
        }
        this.loginProvider.getRegistrationSettings().subscribe(settings => {
            this.settings = settings;
        });
    }

    onSubmit() {
        if (this.form.valid) {
            this.login.emit(this.form.value);
        }
    }

    onSamlLogin() {
        this.samlLogin.emit();
    }

    resolveCaptcha(response: string) {
        const captchaField = this.form.get('captcha');
        if (captchaField) {
            captchaField.setValue(response);
            captchaField.markAsTouched();
        }
    }
}
