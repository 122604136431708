import { Component, OnInit } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { Identity, Signin } from '../../../infrastructure/models';
import { IdentityStore } from '../../../infrastructure/store';
import { ApplicationSettingsProvider } from '../../providers';
import { catchError, first } from 'rxjs/operators';
import { ToasterService } from '../../../infrastructure/services';
import { ExternalLoginProvider } from '../../../infrastructure/providers/external-login.provider';
import { AccountNameResolverProvider } from '../../../infrastructure/providers';

@Component({
    selector: 'cb-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    public identity: Observable<Identity>;
    public isTakeSurvey = true;

    constructor(
        private identityStore: IdentityStore,
        private applicationSettingsProvider: ApplicationSettingsProvider,
        private toasterService: ToasterService,
        private accountNameResolverProvider: AccountNameResolverProvider,
        private externalLoginProvider: ExternalLoginProvider
    ) {
        this.identity = this.identityStore.identity;
    }

    ngOnInit() {
        this.prepareForRedirect();
    }

    prepareForRedirect() {
        this.identity.subscribe(i => {
            if (i && i.access_token) {
                this.identityStore.goToRedirectUri(i, true);
            }
        });
    }

    public onLogin(user: Signin) {
        this.identityStore.doSignIn(user);
    }

    onSamlLogin(): void {
        forkJoin([
            this.applicationSettingsProvider.isSamlEnabled(),
            this.accountNameResolverProvider.getTakeSurveyAccount(),
        ])
            .pipe(
                first(),
                catchError(err => this.toasterService.showError({ message: 'Invalid account name' }, true)))
            .subscribe(([isEnabled, account]) => {
                if (isEnabled) {
                    const ssoLink = this.externalLoginProvider.getSamlSigninLink(account);
                    window.location.href = ssoLink;
                } else {
                    this.toasterService.showError({ message: 'SAML is disabled for your account' }, true)
                }
            });
    }
}
